<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="2" md="2">
                <v-select
                  label="Status"
                  v-model="filter.status"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  @change="optionChange"
                ></v-select>
              </v-col>
              <v-col cols="2" md="2">
                <v-select
                  label="Jenis Upah"
                  v-model="filter.jenis_upah"
                  :items="jenis_upah"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  @change="optionChange"
                ></v-select>
              </v-col>
              <v-col cols="4" md="4">
                <v-autocomplete
                  v-model="filter.bagian"
                  :items="bagians"
                  dense
                  outlined
                  label="Bagian"
                  @change="optionChange"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" small @click="optionChange()"> Lihat </v-btn>
                <v-btn color="primary" class="ml-2" small @click="cetak"> Cetak </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <br />
          <div id="printMe">
            <table cellpadding="0" cellspacing="0" class="header">
              <tr>
                <td style="width: 20%">
                  <img style="width: 150px; height: auto" :src="require('@/assets/images/logos/logo.png')" />
                </td>
                <td style="width: 80%">
                  <center>
                    <span v-if="kop.kop_1 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_1 }} <br
                    /></span>
                    <span v-if="kop.kop_2 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_2 }} <br
                    /></span>
                    <span v-if="kop.kop_3 != ''" style="font-size: 10pt">{{ kop.kop_3 }} ><br /></span>
                    <span v-if="kop.kop_4 != ''" style="font-size: 10pt">{{ kop.kop_4 }} ><br /></span>
                  </center>
                </td>
                <td style="width: 20%">&nbsp;</td>
              </tr>
            </table>
            <h3 style="text-align: center">Laporan Data Anggota</h3>
            <table cellpadding="0" cellspacing="0" class="body">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Jenis Kelamin</th>
                  <th>Tgl Lahir</th>
                  <th>Jenis Upah</th>
                  <th>Bagian</th>
                  <th>Wilayah</th>
                  <th>S.Pokok</th>
                  <th>S.Wajib</th>
                  <th>Tabungan</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in desserts" :key="index">
                  <td style="text-align: center">{{ index + 1 }}</td>
                  <td>{{ item.kode_anggota }}</td>
                  <td>{{ item.nama }}</td>
                  <td>{{ item.jenis_kelamin }}</td>
                  <td>{{ formatDate(item.tgl_lahir) }}</td>
                  <td>{{ item.jenis_upah }}</td>
                  <td>{{ item.divisi }}</td>
                  <td>{{ item.wilayah }}</td>
                  <td style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(item.s_pokok) }}
                  </td>
                  <td style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(item.s_wajib) }}
                  </td>
                  <td style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(item.tabungan) }}
                  </td>
                  <td>{{ item.status_aktif_anggota }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import '@/styles/print.css'
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  data: () => ({
    desserts: [],
    loading: true,
    output: null,
    kop: {},
    bagians: [],
    filter: {
      // tanggal_mulai: new Date().toJSON().slice(0, 8).replace(/-/g, '-') + '01',
      // tanggal_akhir: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      jenis_upah: 'HARIAN',
      status: 'AKTIF',
      bagian: 'SEMUA',
    },
    status: ['AKTIF', 'NON AKTIF'],
    jenis_upah: ['HARIAN', 'MINGGUAN', 'BULANAN'],
  }),

  computed: {
    token() {
      return this.$store.getters.token
    },
  },

  watch: {},

  created() {
    this.getParameterKop()
    this.getBagianAnggota()
    this.getDataFromApi()
  },

  methods: {
    getBagianAnggota() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Laporan/bagian_anggotas`, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.bagians = []
            const arr = response.data.data
            arr.forEach(element => {
              this.bagians.push(element.divisi)
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    async cetak() {
      // Pass the element id here
      await this.$htmlToPaper('printMe', {})
    },
    getParameterKop() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Dashboard/getParameterKop`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.kop = response.data.data.parameter
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      // form.append('tanggal_mulai', this.filter.tanggal_mulai)
      form.append('status_aktif', this.filter.status)
      form.append('jenis_upah', this.filter.jenis_upah)
      form.append('bagian', this.filter.bagian)
      axios
        .post(`${apiRoot}/api/Laporan/anggota`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    optionChange() {
      // if (this.filter.tanggal_mulai != '' && this.filter.tanggal_akhir != '') {
      this.getDataFromApi()
      // }
    },
  },
}
</script>
